<template>
  <div class="pay-container">
    <div>
      <van-submit-bar
        :loading="ispaying"
        :button-text="btnText"
        text-align="left"
        button-color="#A92B2A"
        :price="price"
        @submit="goPay">
        <template #tip>
          <span v-if="isRedAll">已选：满堂红</span>
          <span v-else>已选：单对点亮 <span v-show="ports > 0">*{{ ports }}</span></span>
        </template>
      </van-submit-bar>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getQueryParam } from '@/utils/url'
import { DLBZFBPay } from '@/api/modules/pay'
import { handlePayment } from '@/utils/payment'
import { getDeviceSign } from '@/api/modules/device'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      btnText: '确认支付',
      state: getQueryParam('state') || '',
      authCode: getQueryParam('auth_code') || '',
      delayTimer: null,
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('pay', ['ispaying']),
    ...mapState('env', ['SET_ENV_TYPE']),
    ...mapState('user', ['openId']),
    ...mapState('temple', ['templeInfoData']),
    ...mapState(['prayName', 'prayComment', 'lightingArr', 'comboId', 'comboContent', 'isRedAll']),
    ...mapState('device', ['device']),
    ...mapGetters('combo', ['getComboById']),
    ...mapGetters('env', ['determineEnvironment']),

    price () {
      if (!this.comboId) {
        return 0
      }
      const combo = this.getComboById(this.comboId)
      if (combo.comboType === '0') {
        return combo.comboMoney * 100
      } else {
        return (combo.comboMoney * 100) * this.ports
      }
    },
    ports () {
      return this.$store.state.lightingArr.length || 0
    },

  },
//  监控data 中数据变化
  methods: {
    ...mapMutations('env', ['setPayType']),
    ...mapMutations('pay', ['setPaying']),
    ...mapMutations('device', ['updateDeviceSignal']),
    ...mapMutations('local', ['setHistoryList']),
    //去支付
    async goPay () {
      this.setPaying(true)
      const payType = this.determineEnvironment(this.templeInfoData.payType)
      this.setPayType(payType)
      //校验  数据是否已经准备好了
      if (!this.verificationData()) {
        this.setPaying(false)
        return
      }
      //将姓名添加到历史记录中
      this.addToFrontIfNotExists()
      //查询设备是否在线
      const { data } = await getDeviceSign(this.device.deviceImei).catch(r=>{
        this.setPaying(false)
      })
      //console.log(typeof +data.data)
      this.updateDeviceSignal(data.data)
      if (+data.data <= 3) {
        this.$toast.fail({
          message: '设备已离线',
          duration: 2000,
        })
        this.setPaying(false)
        return
      }
      //判断当前环境
      //console.log(this.SET_ENV_TYPE)
      // 调用 handlePayment 函数并等待其完成
      try{
        await handlePayment(this.SET_ENV_TYPE, {
          IMEIID: this.device.deviceImei,
          comboId: this.comboId,
          prayName: this.prayName,
          prayComment: this.prayComment,
          portNumber: this.lightingArr,
          openId: this.openId,
          orderPrice: (this.price / 100).toFixed(2)
        })
      }finally {
        // 在非支付宝哆啦宝支付时执行延时操作
        if (this.SET_ENV_TYPE !== 'DLBALIPAY') {
          // 延时2秒后执行 setPaying(false)
          this.delayTimer = setTimeout(() => {
            this.setPaying(false)
          }, 2000)
        }
      }
    },
    verificationData () {
      //名字待定
      if (!this.prayName || this.prayName.trim() === '') {
        // this.$toast('请输入姓名！')
        this.$notify({
          message: '请输入姓名！',
          color: '#000000',
          background: '#FFD700',
        })
        this.$nextTick(() => {
          this.$parent.$refs.luckDreamRef.focusInput() // 聚焦输入框
        })
        return false
      }
      //端口校验
      if (this.lightingArr.length === 0) {
        // this.$toast('请选择端口！')
        this.$notify({
          message: '请选择端口！',
          color: '#000000',
          background: '#FFD700',
        })
        this.$emit('changeDeviceShow', true)
        return false
      }
      //校验套餐是否选择
      if(this.comboId === ''){
        this.$notify({
          message: '请选择套餐！',
          color: '#000000',
          background: '#FFD700',
        })
        return false
      }
      return true
    },
    async goDLBAlipay () {
      if (this.authCode !== '' && this.state !== '') {
        this.setPaying(true)
        await DLBZFBPay({
          state: this.state,
          auth_code: this.authCode
        }).then((res) => {
          window.ap.tradePay(
            {
              tradeNO: res.data.data.AlipayNumber,
            },
            (res) => {
              this.setPaying(false)
              if (res.resultCode === '9000') {
                this.showModal('modal1')
              } else if (res.resultCode === '6001') {
                this.$toast('用户中途取消')
              } else if (res.resultCode === '4000') {
                this.$toast('订单支付失败')
              } else if (res.resultCode === '8000') {
                this.$toast('正在处理中')
              } else if (res.resultCode === '6002') {
                this.$toast('网络连接出错')
              } else if (res.resultCode === '99') {
                this.$toast('用户忘记密码')
              }
              this.$router.push({ path: '/devicelight' });
            }
          )
        }).catch(r => {
          alert(r)
          this.setPaying(false)
          this.$router.push({ path: '/devicelight' });
        })
      }
    },
    addToFrontIfNotExists () {
      if (this.prayName) {
        const oldArr = this.$store.state.local.historyList
        let obj = { text: this.prayName }
        if (oldArr && oldArr.length > 0) {
          const index = oldArr.findIndex(item => item.text === obj.text)
          if (index !== -1) {
            // 如果数组中已经存在该数据，则将其从数组中删除
            oldArr.splice(index, 1)
          }
          // 将新元素添加到数组的最前面
          oldArr.unshift(obj)
          if (oldArr.length > 3) {
            // console.log(oldArr.length,99999)
            oldArr.pop()
          }
          this.setHistoryList(oldArr)
        } else {
          this.setHistoryList(new Array(obj))
        }
      }
    },

    clearDelay () {
      // 在方法中使用 clearTimeout 清理定时器
      if (this.delayTimer !== null) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null // 清理后将 delayTimer 设置为 null
      }
    },
  },
//  方法集合
  watch: {},
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    this.goDLBAlipay()
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
    this.clearDelay()
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.van-submit-bar__tip {
  padding: 8px 22px;
  font-size: 14px;
}

.van-submit-bar__text {
  padding-left: 5px;
  font-size: 18px;
}

.van-submit-bar__price {
  font-size: 16px;

  .van-submit-bar__price--integer {
    font-size: 22px;
  }
}

.van-submit-bar__button {
  width: 150px;
  height: 48px;
}
</style>
