<template>
  <div class="menu-container">
    <div class="content-box">
      <van-cell-group class="cell-group" :border="false">
        <van-field
          :value="comboContent"
          label="套餐已选:"
          placeholder="请选择套餐"
          :border="false"
          readonly
          autosize
          right-icon="arrow"
          @click="$emit('changeComboShow',true)"
        >
        </van-field>
        <van-field
          ref="wrapper"
          :value="lightingContent"
          label="心灯已选:"
          :border="false"
          readonly
          clickable
          right-icon="arrow"
          autosize
          placeholder="请选择心灯"
          @click="deviceStatusShowFn"
        >
        </van-field>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'

import { mapGetters, mapMutations, mapState } from 'vuex'
import { getDeviceStatusMap } from '@/api/modules/device'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {}
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState(['comboContent', 'lightingArr']),
    ...mapGetters(['lightingContent']),
    ...mapState('device',['device']),
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations('device',['setDeviceStatusMap']),
    async deviceStatusShowFn(){
      //获取设备状态
      const {data} = await getDeviceStatusMap(this.device.deviceImei,this.device.deviceType);
      this.setDeviceStatusMap(data.data);
      this.$emit('changeLightShow',true)


    },

  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>
<style lang="less" scoped>
.menu-container {
  padding: 0 15px;
  .content-box {
    width: 345px;
    height: 120px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */

    .van-cell-group {
      width: 100%; /* 宽度撑满父级容器 */
      height: 95%; /* 高度撑满父级容器 */
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background: #fbf1ee !important;
      border-radius: 10px;

      .van-cell {
        background: #fbf1ee;
        :deep .van-cell__title {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }
  }

  :deep .van-field__control {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; /* 解决ios滑动不流畅问题 */

  }
  :deep .van-field__body{
    font-size: 16px;
  }

}


</style>
