import request, { get } from '@/utils/request'

//获取首页订单
export const getPrayList = (IMEIID) => {
  return request.get('/pray/getPrayList', {
    params: {
      IMEIID
    }
  }).catch(r=>{
    console.log(r)
  })
}
