export default function getMinutes(mins=0) {
    let dd = Math.floor(mins / 60 / 24);
    let hh = Math.floor(mins /60 % 24)
    let mm = Math.floor(mins % 60);

    let result = '';
    if(dd > 0){
        result = result + dd + '天';
    }
    if(hh > 0){
        result = result + hh + '小时';
    }
    if(mm > 0){
        if(dd > 0){
            return result = result + mm + '分';
        }
        result = result + mm + '分钟';
    }
    return result;  
    // let d = 24 * 60;
    // let h = 60;
    // let dd = Math.floor(mins / d);
    // let hh = Math.floor((mins % d) / d);
    // let mm = Math.floor(mins % h);
    // return `${dd}天${hh}时${mm}分`
}